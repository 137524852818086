export function toggleAboutMe() {
  const targets = document.querySelectorAll(".toggle-entry");
  const imageSources = document.querySelectorAll(".about-me-image-source");
  const imageTarget = document.querySelector(".about-me-image-target");

  const imageTargetSrc = imageTarget.src;

  targets.forEach((target) => {
    target.addEventListener("click", () => {
      const activeEntry = document.querySelector(".about-me__entry--active");
      const imageSrc = Array.from(imageSources).find((image) =>
        image.classList.contains("about-me__image--" + target.dataset.entryTarget)
      )?.src;

      if (!target.classList.contains("about-me__entry--active")) {
        activeEntry.classList.remove("about-me__entry--active");
        target.classList.add("about-me__entry--active");
      }
      imageTarget.src = +target.dataset.entryTarget === 1 ? imageTargetSrc : imageSrc;
    });

    target.addEventListener("mouseenter", (event) => {
      const activeEntry = document.querySelector(".about-me__entry--active");
      const imageSrc = Array.from(imageSources).find((image) =>
        image.classList.contains("about-me__image--" + target.dataset.entryTarget)
      )?.src;

      if (!target.classList.contains("about-me__entry--active")) {
        activeEntry.classList.remove("about-me__entry--active");
        target.classList.add("about-me__entry--active");
      }
      imageTarget.src = +target.dataset.entryTarget === 1 ? imageTargetSrc : imageSrc;
    });
  });
}
