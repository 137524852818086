import Swiper from "./../swiper-bundle.min";

export function slider() {
  const swiper = new Swiper(".swiper", {
    loop: true,
    speed: 6000,
    freeMode: true,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1.3,
        slidesOffsetAfter: 24,
        spaceBetween: 15,
      },
      760: {
        spaceBetween: 18,
        slidesOffsetAfter: 33,
        slidesPerView: 1.6,
      },
      1020: {
        spaceBetween: 31,
        slidesPerView: 2.6,
      },
    },
  });
}
