export function playVideo() {
  const button = document.querySelector(".play-video");
  const video = document.getElementById("intro-video");
  const videoButton = document.getElementById("video-play-button");

  button.addEventListener("click", () => {
    video.play();
    video.controls = true;
    videoButton.style.display = "none";
  });

  video.addEventListener("ended", (event) => {
    video.controls = false;
    video.load();
    videoButton.style.display = "flex";
  });

  video.addEventListener("pause", (event) => {
    video.controls = false;
    videoButton.style.display = "flex";
  });
}
