import { scrollToModule } from "./components/scroll-to";
import { toggleMenu } from "./components/toggle-menu";
import { playVideo } from "./components/play-video";
import { slider } from "./components/slider";
import { toggleAboutMe } from "./components/toggle-about-me";
document.addEventListener("DOMContentLoaded", function () {
  document.querySelector("body").classList.add("loaded");

  toggleAboutMe();
  slider();
  playVideo();
  toggleMenu();
  scrollToModule();
});
