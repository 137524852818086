export function scrollToModule() {
  const scrollTos = document.querySelectorAll(".scroll-to");
  scrollTos.forEach((scrollTo) => {
    scrollTo.addEventListener("click", () => {
      const target = document.getElementById(scrollTo.getAttribute("data-scroll-to-target"));
      const items = document.querySelectorAll(".set-state");

      items.forEach((item) => {
        if (item.classList.contains("active")) {
          item.classList.remove("active");
          document.body.style.overflow = "auto";
        }
      });

      target.scrollIntoView({ behavior: "smooth" });
    });
  });
}
