export function toggleMenu() {
  const button = document.querySelector(".toggle-menu");
  const items = document.querySelectorAll(".set-state");

  button.addEventListener("click", () => {
    items.forEach((item) => {
      if (item.classList.contains("active")) {
        item.classList.remove("active");
        document.body.style.overflow = "auto";
      } else {
        item.classList.add("active");
        document.body.style.overflow = "hidden";
      }
    });
  });
}
